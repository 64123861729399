import Swiper from '@/vue/pages/Swiper.vue'
import { createApp } from 'vue'

import SwiperPlugin from '@/js/plugins/swiper'

const main = async () => {
  const app = createApp(Swiper)
  const root = app.use(SwiperPlugin).mount('#swiper')
  return root
}

main().then(() => {})
