<template>
  <swiper
    ref="el"
    :modules="$swiperModules"
    :slides-per-view="Number(perView)"
    :space-between="20"
    :autoplay="{
      delay: Number(delay),
      disableOnInteraction: false,
    }"
    :pagination="{ clickable: true }"
    :breakpoints="{
      0: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      600: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      800: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1000: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    }"
  >
    <swiper-slide v-for="(testimonial, index) in testimonials" :key="index">
      <div class="single-testimonial text-center mt-55">
        <div class="testimonial-caption">
          <p>{{ testimonial.testimonial }}</p>
        </div>
        <div class="testimonial-founder d-flex align-items-center justify-content-center">
          <div class="founder-img">
            <img :src="testimonial.avatar" alt="" />
          </div>
          <div class="founder-text">
            <span>{{ testimonial.author }}</span>
            <p>{{ testimonial.position }}</p>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      delay: 5000,
      perView: 3,
      testimonials: [],
      test: [],
    }
  },
  mounted() {
    this.delay = this.$el.parentNode.getAttribute('data-delay')
    this.perView = this.$el.parentNode.getAttribute('data-perView')

    const encodedData = this.$el.parentNode.getAttribute('data-testimonials')
    const decodedString = new DOMParser().parseFromString(encodedData, 'text/html').body.textContent
    this.testimonials = JSON.parse(decodedString)
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/color';
@import '@/assets/scss/variables';

.single-testimonial {
  background: $card_item_color;
  padding: 50px 50px;
  border-radius: 12px;
  height: 90%;
}

.testimonial-caption {
  display: flex;
  justify-content: center;

  img {
    padding-bottom: 22px !important;
    position: absolute;
    top: -3px;
  }

  p {
    font-size: 16px;
    color: $heading_color;
    color: #fff;
    line-height: 1.5;
    margin-bottom: 37px;

    @media #{$lg} {
      margin-bottom: 15px;
    }

    @media #{$sm} {
      width: 99%;
    }
  }
}

.testimonial-founder {
  .founder-img {
    margin-right: 20px;
    width: 68px;
    height: auto;

    img {
      width: 68px;
      height: 68px;
      border-radius: 150px;
    }
  }

  .founder-text {
    padding-top: 4px;
    text-align: left;

    span {
      color: $white;
      font-weight: 700;
      line-height: 1.5;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
    }

    p {
      color: $white;
      font-size: 14px;
      margin-bottom: 0px !important;
    }
  }
}
</style>
